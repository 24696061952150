<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="개선 목록"
        tableId="impr01"
        :columns="gridImpr.columns"
        :data="hazard.imprs"
        :merge="gridImpr.merge"
        :gridHeight="gridHeight"
        @linkClick="linkClick"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridImpr"
        title="항목외 개선 목록"
        :columns="gridImpr2.columns"
        :data="gridImpr2.data"
        :gridHeight="gridHeight2"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled2 && popupParam.heaMuscleSopId" label="LBLIMPRREQUEST" :showLoading="false"  icon="add" @btnClicked="addImpr" />
            <c-btn v-if="editable && !disabled2 && popupParam.heaMuscleSopId" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'muscleImprStatus',
  props: {
    hazard: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',  // 근골격계 단위작업 일련번호
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        heaMuscleSopStepCd: '',  // 진행상태
        processCd: '',  // 공정코드
        processName: '',  // 공정명
        mdmSopId: '',  // 안전작업 표준 일련번호
        sopName: '',  // 세부공정(작업) 명
        heaInvestigationTypeCd: '',  // 조사구분
        investigationDate: '',  // 조사일시
        deptCd: '',  // 조사자 부서
        userId: '',  // 조사자 id
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        heavyWorks: [], // 부담작업
        heavyWorkIncludes: [], // 부담작업 결과 O
        heavyWorkHazards: [], // 유해요인
        workers: [], // 작업자
        situations: [], // 상황조사
        results: [], // 결과표
        imprs: [], // 개선
      }),
    },
    popupParam: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridImpr: {
        merge: [
          { index: 0, colName: 'heaMuscleSopResultId' },
          { index: 1, colName: 'heaMuscleSopResultId' },
          { index: 2, colName: 'heaMuscleSopResultId' },
          { index: 3, colName: 'heaMuscleSopResultId' },
        ],
        columns: [
          {
            name: 'heaAssessmentToolCd',
            field: 'heaAssessmentToolCd',
            label: '작업분석․평가도구',
            align: 'center',
            type: 'select',
            codeGroupCd: 'HEA_ASSESSMENT_TOOL_CD',
            sortable: false,
          },
          {
            name: 'resultName',
            field: 'resultName',
            label: '평가결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'judgment',
            field: 'judgment',
            label: '판정',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'workerOpinion',
            field: 'workerOpinion',
            label: '근로자의견',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            label: '요청부서정보',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        height: '500px'
      },
      gridHeight: '400px',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      imprData: null,
      listUrl: '',
      gridImpr2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      impListUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight2() {
      return this.contentHeight ? (this.contentHeight - 530) + 'px' : '500px'
    },
    disabled2() {
      return this.hazard.heaMuscleSopStepCd === 'HMS000010';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSopResult.list.url
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;
      // code setting
      // list setting
      this.getImpList();
    },
    research() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaMuscleSopId: this.popupParam.heaMuscleSopId
      };
      this.$http.request((_result) => {
        this.$set(this.hazard, 'results', _result.data);
      },);
    },
    linkClick2(row) {
      this.linkClick(row, row, '2');
    },
    linkClick(data, row, num) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      }
    },
    closeImprPopup2() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    getImpList() {
      if (this.popupParam.heaMuscleSopId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000170', 
          ibmTaskUnderTypeCd: 'ITTU000185', 
          relationTableKey: this.popupParam.heaMuscleSopId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr2.data = _result.data;
        },);
      }
    },
    addImpr() {
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: '[근골결계조사계획]' + this.hazard.sopName,
        relationTableKey: this.popupParam.heaMuscleSopId,
        ibmTaskTypeCd: 'ITT0000170',
        ibmTaskUnderTypeCd: 'ITTU000185', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = 'LBLIMMEDIATE';
      this.popupOptions.param = {
        requestContents: '[근골결계조사계획]' + this.hazard.sopName,
        relationTableKey: this.popupParam.heaMuscleSopId,
        ibmTaskTypeCd: 'ITT0000170',
        ibmTaskUnderTypeCd: 'ITTU000185', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>